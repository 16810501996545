import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
        path: '/',
        name: 'Home',
        component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
      },

      // MASTER
      {
        path: 'master/fakultas',
        name: 'Master Fakultas',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Fakultas.vue'),
      },
      {
        path: 'master/jurusan',
        name: 'Master Jurusan',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Jurusan.vue'),
      },
      {
        path: 'master/prodi',
        name: 'Master Program Studi',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Prodi.vue'),
      },
      {
        path: 'master/jenis-mk',
        name: 'Master Jenis Mata Kuliah',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/JenisMK.vue'),
      },
      {
        path: 'master/bentuk-mk',
        name: 'Master Bentuk Mata Kuliah',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/BentukMK.vue'),
      },
      {
        path: 'master/metode-pembelajaran',
        name: 'Master Metode Pembelajaran',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/MetodePembelajaran.vue'),
      },
      {
        path: 'master/jenis-evaluasi',
        name: 'Master Jenis Evaluasi',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/JenisEvaluasi.vue'),
      },
      {
        path: 'master/jadwal',
        name: 'Master Jadwal',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Jadwal.vue'),
      },
      {
        path: 'master/hak-akses',
        name: 'Master Hak Akses',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/HakAkses.vue'),
      },
      {
        path: 'master/dosen',
        name: 'Master Dosen',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Dosen.vue'),
      },
      {
        path: 'master/operator',
        name: 'Master Operator',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/Operator.vue'),
      },
      {
        path: 'master/ubah-password',
        name: 'Master Ubah Password',
        component: () => import( /* webpackChunkName: "about" */ '../views/master/UbahPassword.vue'),
      },


      // KURIKULUM
      {
        path: 'kurikulum/daftar-kurikulum',
        name: 'Daftar Kurikulum',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/Kurikulum.vue'),
      },
      {
        path: 'kurikulum/cpl',
        name: 'CPL',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/CPL.vue'),
      },
      {
        path: 'kurikulum/cpl-mkwu',
        name: 'CPL MKWU',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/CPLmkwu.vue'),
      },
      {
        path: 'kurikulum/mata-kuliah',
        name: 'Mata Kuliah',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/MataKuliah.vue'),
      },
      {
        path: 'kurikulum/mata-kuliah/:id',
        name: 'Mata Kuliah Detail',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/MataKuliahDetail.vue'),
      },
      {
        path: 'kurikulum/mata-kuliah/pengampu/:id',
        name: 'Mata Kuliah Pengampu',
        component: () => import( /* webpackChunkName: "about" */ '../views/kurikulum/MataKuliahPengampu.vue'),
      },


      // PENILAIAN
      {
        path: 'penilaian/entri-evaluasi',
        name: 'Entri Evaluasi',
        component: () => import( /* webpackChunkName: "about" */ '../views/penilaian/EntriEvaluasi.vue'),
      },
      {
        path: 'penilaian/entri-evaluasi/:id/:kelas',
        name: 'Entri Evaluasi Detail',
        component: () => import( /* webpackChunkName: "about" */ '../views/penilaian/EntriEvaluasiDetail.vue'),
      },


      // PENILAIAN
      {
        path: 'monitoring/monitoring-evaluasi',
        name: 'Monitoring Evaluasi',
        component: () => import( /* webpackChunkName: "about" */ '../views/monitoring/MonitoringEvaluasi.vue'),
      },
      {
        path: 'monitoring/monitoring-evaluasi/:id/:kelas',
        name: 'Monitoring Evaluasi Detail',
        component: () => import( /* webpackChunkName: "about" */ '../views/monitoring/MonitoringEvaluasiDetail.vue'),
      },


      // SYNC
      {
        path: 'sync/prodi',
        name: 'Sync Prodi',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/Prodi.vue'),
      },
      {
        path: 'sync/kurikulum',
        name: 'Sync Kurikulum',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/Kurikulum.vue'),
      },
      {
        path: 'sync/matakuliah',
        name: 'Sync Mata Kuliah',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/MataKuliah.vue'),
      },
      {
        path: 'sync/dosen',
        name: 'Sync Dosen',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/Dosen.vue'),
      },
      {
        path: 'sync/mahasiswa',
        name: 'Sync Mahasiswa',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/Mahasiswa.vue'),
      },
      {
        path: 'sync/hakakses',
        name: 'Sync Hak Akses',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/HakAkses.vue'),
      },
      {
        path: 'sync/semester',
        name: 'Sync Semester',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/Semester.vue'),
      },
      {
        path: 'sync/kelaskuliah',
        name: 'Sync Kelas Kuliah',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/KelasKuliah.vue'),
      },
      {
        path: 'sync/kelaskuliahmhs',
        name: 'Sync Kelas Kuliah Mhs',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/KelasKuliahMhs.vue'),
      },
      {
        path: 'sync/mkpengampu',
        name: 'Sync MK Pengampu',
        component: () => import( /* webpackChunkName: "about" */ '../views/sync/MKPengampu.vue'),
      },


      // MENU
      {
        path: 'menu/:slug',
        name: 'menu',
        component: () => import( /* webpackChunkName: "about" */ '../views/Menus.vue')
      },






      // ROUTES ADMIN
      // PEGAWAI
      // 
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import( /* webpackChunkName: "about" */ '../views/Categories.vue')
      },
      {
        path: 'products',
        name: 'products',
        component: () => import( /* webpackChunkName: "about" */ '../views/Products.vue')
      },
      {
        path: 'category/:slug',
        name: 'category',
        component: () => import( /* webpackChunkName: "about" */ '../views/Category.vue')
      },
      {
        path: 'product/:slug',
        name: 'product',
        component: () => import( /* webpackChunkName: "about" */ '../views/Product.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// tambahkan ini untuk melakukan pengecekan pada setiap routing
router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters['auth/guest']) {
      // tampilkan pesan bahwa harus login dulu
      store.dispatch('alert/set', {
        status: true,
        text: 'Login first',
        color: 'error',
      })
      // tampilkan form login
      store.dispatch('dialog/setComponent', 'login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router