<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="/img/logo/logo-ung.png" max-width="40px" class="mb-1"></v-img>
      <v-toolbar-title to="/" class="hidden-xs-only font-weight-medium">
        &nbsp;&nbsp;{{ appNameDetail.toUpperCase() }}
      </v-toolbar-title>
      <v-toolbar-title to="/" class="hidden-sm-and-up font-weight-medium">
        &nbsp;&nbsp;{{ appName }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="!guest">
        <div class="locale-changer mr-6 mt-6" style="width: 80px">
          <v-select
            v-model="$i18n.locale"
            :items="langs"
            item-text="text"
            item-value="id"
            solo
            light
            dense
            v-on:change="langChange"
          ></v-select>
        </div>

        <v-menu offset-y tile>
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Dropdown
            </v-btn> -->
            <v-avatar size="40" v-bind="attrs" v-on="on">
              <v-img
                src="/img/logo/photo.jpg"
                style="width: 100%"
                v-bind="attrs"
                v-on="on"
              ></v-img>
            </v-avatar>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item class="my-2">
                <v-avatar size="60">
                  <v-img src="/img/logo/photo.jpg"></v-img>
                </v-avatar>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    ><span class="font-weight-medium">{{ user.data.nama }}</span
                    ><br /><span class="font-weight-medium"
                      ><small>
                        <span v-if="user.data.aktif.operator == true">
                          OPERATOR
                        </span>
                        <span v-if="user.data.aktif.role == 1">
                          ADMINISTRATOR
                        </span>
                        <span v-else-if="user.data.aktif.role == 2">
                          TATA USAHA
                        </span>
                        <span v-else-if="user.data.aktif.role == 3">
                          KAJUR
                        </span>
                        <span v-else-if="user.data.aktif.role == 4">
                          KAPRODI
                        </span>
                        <span v-else-if="user.data.aktif.role == 5">
                          DOSEN
                        </span>
                        <span v-else-if="user.data.aktif.role == 6">
                          KEPALA PUSAT MKWU </span
                        ><br />
                        <span
                          v-if="
                            user.data.aktif.role != 1 &&
                            user.data.aktif.role != 6
                          "
                        >
                          <span v-if="user.data.aktif.prodi.jenjang == 'A'">
                            S3
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'B'"
                          >
                            S2
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'C'"
                          >
                            S1
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'D'"
                          >
                            D4
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'E'"
                          >
                            D3
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'F'"
                          >
                            D2
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'G'"
                          >
                            D1
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'H'"
                          >
                            Sp-1
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'I'"
                          >
                            Sp-2
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'J'"
                          >
                            PROFESI
                          </span>
                          <span
                            v-else-if="user.data.aktif.prodi.jenjang == 'X'"
                          >
                            NON AKADEMIK
                          </span>
                          {{ user.data.aktif.prodi.nama_prodi_id }}</span
                        ></small
                      ></span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item
                @click="dialogRole = true"
                color="primary"
                v-if="user.data.aktif.operator != true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title>{{
                    $t("ganti_hak_akses")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="`/${$i18n.locale}` + '/master/ubah-password'"
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title>{{
                    $t("master.ubah_password")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout()" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <template v-else>
        <div class="locale-changer mr-6 mt-6" style="width: 80px">
          <v-select
            v-model="$i18n.locale"
            :items="langs"
            item-text="text"
            item-value="id"
            solo
            light
            dense
            v-on:change="langChange"
          ></v-select>
        </div>
        <v-btn
          color="secondary"
          class="black--text"
          @click="setDialogComponent('login')"
        >
          <v-icon left small>mdi-login-variant</v-icon>
          {{ $t("login") }}
        </v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" clipped>
      <!-- Logo GUEST -->
      <v-container v-if="guest">
        <v-fade-transition mode="out-in">
          <v-row class="mt-2">
            <v-col cols="12" align="center">
              <v-img src="/img/logo/logo-ung.png" style="width: 45%"></v-img>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <v-container v-else>
        <v-fade-transition mode="out-in">
          <v-row class="mt-2">
            <v-col cols="12" align="center">
              <v-avatar size="100">
              <v-img src="/img/logo/photo.jpg" style="width: 45%"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container>
      <!-- AVATAR USER -->
      <!-- <v-container style="width: 40%" v-if="!guest">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12">
              <v-avatar size="80">
                <v-img
                  :src="getAvatar(user.data.photo)"
                  style="width: 100%"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-container> -->
      <!-- NAMA GUEST -->
      <div class="text-center mb-2" style="color: black" v-if="guest">
        <b>{{ appNameNormal }}</b
        ><br /><small>{{ appNameUNG }}</small>
      </div>
      <!-- NAMA USER -->
      <div class="text-center mb-2" style="color: black" v-if="!guest">
        <b>{{ user.data.nama }}</b
        ><br /><small>({{ user.data.username }})</small><br />
        <span class="text-caption font-weight-medium">
          <small>
            <span v-if="user.data.aktif.operator == true"> OPERATOR </span>
            <span v-if="user.data.aktif.role == 1"> ADMINISTRATOR </span>
            <span v-else-if="user.data.aktif.role == 2"> TATA USAHA </span>
            <span v-else-if="user.data.aktif.role == 3"> KAJUR </span>
            <span v-else-if="user.data.aktif.role == 4"> KAPRODI </span>
            <span v-else-if="user.data.aktif.role == 5"> DOSEN </span>
            <span v-else-if="user.data.aktif.role == 6">
              KEPALA PUSAT MKWU
            </span>
            <br />
            <span v-if="user.data.aktif.role != 1 && user.data.aktif.role != 6">
              <span v-if="user.data.aktif.prodi.jenjang == 'A'"> S3 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'B'"> S2 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'C'"> S1 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'D'"> D4 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'E'"> D3 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'F'"> D2 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'G'"> D1 </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'H'">
                Sp-1
              </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'I'">
                Sp-2
              </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'J'">
                PROFESI
              </span>
              <span v-else-if="user.data.aktif.prodi.jenjang == 'X'">
                NON AKADEMIK
              </span>
              {{ user.data.aktif.prodi.nama_prodi_id }}</span
            ></small
          ></span
        >
      </div>

      <!-- <div class="pb-5 text-center" v-if="!guest">
        <v-btn color="orange" class="white--text mt-2" tile small>
          Lihat Profil </v-btn
        >&nbsp;
        <v-btn color="blue" class="white--text mt-2" tile small>
          Foto Profil
        </v-btn>
      </div> -->

      <v-divider></v-divider>

      <!-- BELUM LOGIN -->
      <v-list v-if="guest" dense>
        <v-list-item
          v-for="(item, index) in menus"
          :key="`menu-` + index"
          :to="item.route"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- SUDAH LOGIN -->
      <div v-if="!guest">
        <v-list dense>
          <v-list-item
            v-for="(item, index) in menus"
            :key="`menu-` + index"
            :to="item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- ROLE 1 ADMIN -->
        <v-list v-if="this.user.data.aktif.role == '1'" dense>
          <!-- Menu Kurikulum Koordinator -->
          <v-subheader class="ml-3"
            ><b>{{ $t("kurikulum.kurikulum") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuKurikulum"
            :key="`menu2-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Penilaian -->
          <v-subheader class="ml-3"
            ><b>{{ $t("penilaian.penilaian") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuPenilaian"
            :key="`menu4-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Monitoring -->
          <v-subheader class="ml-3"
            ><b>{{ $t("monitoring.monitoring") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuMonitoring"
            :key="`menu5-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Master -->
          <v-subheader class="ml-3"><b>Master</b></v-subheader>
          <v-list-item
            v-for="(item, index) in menuMaster"
            :key="`menu3-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="`/${$i18n.locale}` + child.route"
            >
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ $t(child.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Menu Sync -->
          <v-list-group
            v-for="item in itemsSync"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="`/${$i18n.locale}` + child.route"
            >
              <v-list-item-content class="ml-n4">
                <v-list-item-title>{{ $t(child.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list v-else-if="this.user.data.aktif.role == '4'" dense>
          <!-- Menu Kurikulum Kaprodi -->
          <v-subheader class="ml-3"
            ><b>{{ $t("kurikulum.kurikulum") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuKurikulum"
            :key="`menu2-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Penilaian Kaprodi -->
          <v-subheader class="ml-3"
            ><b>{{ $t("penilaian.penilaian") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuPenilaian"
            :key="`menu4-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Monitoring Kaprodi -->
          <v-subheader class="ml-3"
            ><b>{{ $t("monitoring.monitoring") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuMonitoring"
            :key="`menu5-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Master Kaprodi -->
          <v-subheader class="ml-3"><b>Master</b></v-subheader>
          <v-list-item
            v-for="(item, index) in menuMaster"
            :key="`menu3-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else-if="this.user.data.aktif.role == '5'" dense>
          <!-- Menu Kurikulum Dosen -->
          <v-subheader class="ml-3"
            ><b>{{ $t("kurikulum.kurikulum") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuKurikulum"
            :key="`menu2-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Menu Penilaian Dosen -->
          <v-subheader class="ml-3"
            ><b>{{ $t("penilaian.penilaian") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuPenilaian"
            :key="`menu4-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else-if="this.user.data.aktif.role == '6'" dense>
          <!-- Menu Kurikulum Kapus -->
          <v-subheader class="ml-3"
            ><b>{{ $t("kurikulum.kurikulum") }}</b></v-subheader
          >
          <v-list-item
            v-for="(item, index) in menuKurikulumKapus"
            :key="`menu2-` + index"
            :to="`/${$i18n.locale}` + item.route"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- ROLE SELAIN ADMIN -->
        <v-list v-else dense> </v-list>
      </div>
    </v-navigation-drawer>

    <alert />

    <keep-alive>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialogbottom-transition"
      >
        <component :is="currentComponent" @closed="setDialogStatus"></component>
      </v-dialog>
    </keep-alive>

    <v-main class="bgcolor">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog v-model="dialogRole" width="700" v-if="!guest">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("hak_akses") }}
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                {{ $t("hak_akses_sekarang") }}<br /><b>
                  <span v-if="user.data.aktif.role == 1"> ADMINISTRATOR </span>
                  <span v-else-if="user.data.aktif.role == 2">
                    TATA USAHA
                  </span>
                  <span v-else-if="user.data.aktif.role == 3"> KAJUR </span>
                  <span v-else-if="user.data.aktif.role == 4"> KAPRODI </span>
                  <span v-else-if="user.data.aktif.role == 5"> DOSEN </span>
                  <span v-else-if="user.data.aktif.role == 6">
                    KEPALA PUSAT MKWU
                  </span>
                  <span
                    v-if="
                      user.data.aktif.role != 1 && user.data.aktif.role != 6
                    "
                    >-
                    <span v-if="user.data.aktif.prodi.jenjang == 'A'">
                      S3
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'B'">
                      S2
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'C'">
                      S1
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'D'">
                      D4
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'E'">
                      D3
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'F'">
                      D2
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'G'">
                      D1
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'H'">
                      Sp-1
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'I'">
                      Sp-2
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'J'">
                      PROFESI
                    </span>
                    <span v-else-if="user.data.aktif.prodi.jenjang == 'X'">
                      NON AKADEMIK
                    </span>
                    {{ user.data.aktif.prodi.nama_prodi_id }}
                  </span></b
                >
              </v-col>
              <!-- <v-col cols="4">
                <v-subheader>{{ $t("hak_akses") }}</v-subheader>
              </v-col> -->
              <v-col cols="12">
                <v-select
                  v-model="role"
                  :items="roleItems"
                  item-value="id"
                  item-text="role"
                  outlined
                  dense
                  :label="'Daftar ' + $t('hak_akses')"
                  return-object
                >
                  <template v-slot:selection="{ item }">
                    <span v-if="item.role == 1"> ADMINISTRATOR </span>
                    <span v-else-if="item.role == 2"> TATA USAHA </span>
                    <span v-else-if="item.role == 3"> KAJUR </span>
                    <span v-else-if="item.role == 4"> KAPRODI </span>
                    <span v-else-if="item.role == 5"> DOSEN </span>
                    <span v-else-if="item.role == 6"> KEPALA PUSAT MKWU </span>
                    <span v-if="item.role != 6"
                      >&nbsp;-
                      <span v-if="item.prodi.jenjang == 'A'"> S3 </span>
                      <span v-else-if="item.prodi.jenjang == 'B'"> S2 </span>
                      <span v-else-if="item.prodi.jenjang == 'C'"> S1 </span>
                      <span v-else-if="item.prodi.jenjang == 'D'"> D4 </span>
                      <span v-else-if="item.prodi.jenjang == 'E'"> D3 </span>
                      <span v-else-if="item.prodi.jenjang == 'F'"> D2 </span>
                      <span v-else-if="item.prodi.jenjang == 'G'"> D1 </span>
                      <span v-else-if="item.prodi.jenjang == 'H'"> Sp-1 </span>
                      <span v-else-if="item.prodi.jenjang == 'I'"> Sp-2 </span>
                      <span v-else-if="item.prodi.jenjang == 'J'">
                        PROFESI
                      </span>
                      <span v-else-if="item.prodi.jenjang == 'X'">
                        NON AKADEMIK
                      </span>

                      {{ item.prodi.nama_prodi_id }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span v-if="item.role == 1"> ADMINISTRATOR </span>
                    <span v-else-if="item.role == 2"> TATA USAHA </span>
                    <span v-else-if="item.role == 3"> KAJUR </span>
                    <span v-else-if="item.role == 4"> KAPRODI </span>
                    <span v-else-if="item.role == 5"> DOSEN </span>
                    <span v-else-if="item.role == 6"> KEPALA PUSAT MKWU </span>
                    <span v-if="item.role != 6"
                      >&nbsp;-
                      <span v-if="item.prodi.jenjang == 'A'"> S3 </span>
                      <span v-else-if="item.prodi.jenjang == 'B'"> S2 </span>
                      <span v-else-if="item.prodi.jenjang == 'C'"> S1 </span>
                      <span v-else-if="item.prodi.jenjang == 'D'"> D4 </span>
                      <span v-else-if="item.prodi.jenjang == 'E'"> D3 </span>
                      <span v-else-if="item.prodi.jenjang == 'F'"> D2 </span>
                      <span v-else-if="item.prodi.jenjang == 'G'"> D1 </span>
                      <span v-else-if="item.prodi.jenjang == 'H'"> Sp-1 </span>
                      <span v-else-if="item.prodi.jenjang == 'I'"> Sp-2 </span>
                      <span v-else-if="item.prodi.jenjang == 'J'">
                        PROFESI
                      </span>
                      <span v-else-if="item.prodi.jenjang == 'X'">
                        NON AKADEMIK
                      </span>

                      {{ item.prodi.nama_prodi_id }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="dialogRole = false">
            {{ $t("tabel.batal") }}
          </v-btn>
          <v-btn color="primary" @click="gantiHakAkses">
            {{ $t("ganti_hak_akses") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer>
      <v-card-text class="text-center">
        &copy; 2022 —
        <strong>{{ appNameDetail }}</strong>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Alert: () =>
      import(/* webpackChunkName: "alert" */ "@/components/Alert.vue"),
    Search: () =>
      import(/* webpackChunkName: "search" */ "@/components/Search.vue"),
    Login: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    Register: () =>
      import(/* webpackChunkName: "login" */ "@/views/Register.vue"),
  },
  data: () => ({
    drawer: true,
    dialogRole: false,
    role: "",
    roleItems: [],

    langs: [
      { id: "id", text: "ID" },
      { id: "en", text: "EN" },
    ],

    menus: [{ title: "Dashboard", icon: "mdi-home", route: "/" }],

    menuKurikulum: [
      {
        title: "kurikulum.daftar_kurikulum",
        icon: "mdi-briefcase-variant",
        route: "/kurikulum/daftar-kurikulum",
      },
      {
        title: "cpl.cpl",
        icon: "mdi-check-bold",
        route: "/kurikulum/cpl",
      },
      {
        title: "mk.mk",
        icon: "mdi-file",
        route: "/kurikulum/mata-kuliah",
      },
    ],

    menuKurikulumKapus: [
      {
        title: "cpl.cpl",
        icon: "mdi-check-bold",
        route: "/kurikulum/cpl-mkwu",
      },
    ],

    menuPenilaian: [
      {
        title: "penilaian.entri_evaluasi",
        icon: "mdi-account-check",
        route: "/penilaian/entri-evaluasi",
      },
    ],

    menuMonitoring: [
      {
        title: "monitoring.monitoring_evaluasi",
        icon: "mdi-chart-box",
        route: "/monitoring/monitoring-evaluasi",
      },
    ],

    menuMaster: [
      {
        title: "master.dosen",
        icon: "mdi-account-group",
        route: "/master/dosen",
      },
      {
        title: "master.operator",
        icon: "mdi-account-multiple",
        route: "/master/operator",
      },
      {
        title: "hak_akses",
        icon: "mdi-account-cog",
        route: "/master/hak-akses",
      },
    ],

    items: [
      {
        action: "mdi-database",
        items: [
          { title: "master.fakultas", route: "/master/fakultas" },
          { title: "master.jurusan", route: "/master/jurusan" },
          { title: "master.prodi", route: "/master/prodi" },
          { title: "master.jenis_mk", route: "/master/jenis-mk" },
          { title: "master.bentuk_mk", route: "/master/bentuk-mk" },
          {
            title: "master.metode_pembelajaran",
            route: "/master/metode-pembelajaran",
          },
          {
            title: "master.jenis_evaluasi",
            route: "/master/jenis-evaluasi",
          },
          {
            title: "master.jadwal",
            route: "/master/jadwal",
          },
        ],
        title: "master.data",
      },
    ],

    itemsSync: [
      {
        action: "mdi-cog-sync",
        items: [
          { title: "sync.prodi", route: "/sync/prodi" },
          { title: "sync.kurikulum", route: "/sync/kurikulum" },
          { title: "sync.matakuliah", route: "/sync/matakuliah" },
          { title: "sync.dosen", route: "/sync/dosen" },
          { title: "sync.mahasiswa", route: "/sync/mahasiswa" },
          { title: "sync.hakakses", route: "/sync/hakakses" },
          { title: "sync.semester", route: "/sync/semester" },
          { title: "sync.kelaskuliah", route: "/sync/kelaskuliah" },
          { title: "sync.kelaskuliahmhs", route: "/sync/kelaskuliahmhs" },
          { title: "sync.mkpengampu", route: "/sync/mkpengampu" },
        ],
        title: "sync.sync",
      },
    ],
  }),

  created() {
    this.initialize();
  },

  updated() {
    this.initialize();
  },

  watch: {},

  methods: {
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      if (!this.guest) {
        this.getHakAkses();
      }
    },

    getHakAkses() {
      this.roleItems = this.user.data.hak_akses;
      // console.log(this.roleItems);
    },

    langChange: function (e) {
      // console.log(e);

      // this.dialogLoading = true;
      this.$i18n.locale = e;
      this.$router.push({
        params: { lang: e },
      });
    },

    gantiHakAkses() {
      // console.log(this.role.role);
      // console.log(this.role.kode_prodi);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("kode_prodi", this.role.kode_prodi);
      formData.set("role", this.role.role);
      formData.set("token", this.user.data.token);
      this.axios
        .post("/api/auth/gantiHakAkses", formData, config)
        .then((response) => {
          this.$vloading.hide();
          // this.setAuth({});
          let { data } = response;
          // console.log(data);
          this.setAuth(data);
          this.setAlert({
            status: true,
            color: "success",
            text: "Hak akses berhasil diganti!",
          });
          // this.$router.replace({ name: "Home" });
          // // console.log(response.data.data);
          this.dialogRole = false;
          localStorage.clear();
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.$vloading.hide();
          let { data } = error.response;
          this.setAlert({
            status: true,
            color: "error",
            text: data.message,
          });
        });
    },

    logout() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .post("/api/auth/logout", {}, config)
        .then(() => {
          this.$vloading.hide();
          this.setAuth({});
          this.setAlert({
            status: true,
            color: "success",
            text: "Logout successfully",
          });
          localStorage.clear();
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.$vloading.hide();
          let { data } = error.response;
          console.log(data.code);
          if (data.code == undefined) {
            this.setAuth({});
            this.setAlert({
              status: true,
              color: "success",
              text: "Logout successfully",
            });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: data.message,
            });
          }
        });
    },
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
};
</script>
